/*
 * @Author: 张超越 
 * @Date: 2023-08-02 13:48:07 
 * @Last Modified by: 张超越
 * @Last Modified time: 2023-10-25 14:26:53
 */

<template>
  <div v-if="!getDetails._hiddenSelf" class="TreeNode" :style="getStyle">
    <div :class="['self', [getDetails._check, getDetails._half].some(i => i) ? 'active' : '']" @click="handleNameClick">
      <CheckBox :check="getDetails._check" :half="getDetails._half" :disabled="disabled" :nameClickCheck="nameClickCheck" @change="handleCheck"></CheckBox>
      <div class="name" :title="getDetails.name">{{ getDetails.name }}</div>
      <div v-if="getShowArrowBtn" class="arrow" @click.stop="handleToggleChilds">
        <van-icon :name="!getDetails._hiddenChilds ? 'arrow-up' : 'arrow-down'" size="16" />
      </div>
    </div>
    
    <template v-if="!getDetails._hiddenChilds">
      <TreeNode v-for="(treeNode,index) of getChilds" :key="index" :disabled="disabled" :nameClickCheck="nameClickCheck" :level="level+1" :details="treeNode" @change="handleTreeNodeChange(treeNode,index)"></TreeNode>
    </template>
  </div>
</template>

<script>
// Components
import TreeNode from './TreeNode.vue'
import CheckBox from './CheckBox.vue'

export default {
  name: 'TreeNode',
  components: {
    TreeNode,
    CheckBox
  },
  props: {
    details: {
      type: Object,
      default: () => {}
    },
    level: {
      type: Number,
      default: 0
    },
    nameClickCheck: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  computed: {
    getDetails() {
      return this.details
    },
    getChilds() {
      return this.details.children || []
    },
    getStyle() {
      if (this.level === 0) return {}
      return {
        marginLeft: `${8}px`
      }
    },
    getShowArrowBtn() {
      return this.getChilds.length > 0 && this.getChilds.some(item => !item._hiddenSelf)
    }
  },
  methods: {
    handleNameClick() {
      if (this.disabled) return console.log('handleNameClick but disabled is true')
      if (!this.nameClickCheck) return console.log('handleNameClick but nameClickCheck is false')
      this.handleCheck()
    },
    handleCheck() {
      const newCheck = !this.details._check
      
      this.$set(this.details, '_check', newCheck)
      this.$set(this.details, '_half', false)

      // 递归设置子节点
      const setChilds = (childs) => {
        childs.forEach(item => {
          if (item._hiddenSelf) return
          this.$set(item, '_check', newCheck)
          this.$set(item, '_half', false)
          item.children && setChilds(item.children)
        })
      }

      this.details.children && setChilds(this.details.children)
      this.$emit('change', this.details)
    },
    handleTreeNodeChange(treeNode, index) {
      console.log('handleTreeNodeChange', treeNode, index)

      // 子节点变更了，计算该节点的选中状态
      const childs = this.details.children || []
      const childsLength = childs.length
      const checkedLength = childs.filter(item => item._check).length
      const halfLength = childs.filter(item => item._half).length
      const half = checkedLength > 0 && checkedLength < childsLength || halfLength > 0

      const _check = checkedLength === childsLength
      const _half = !_check && half

      this.$set(this.details, '_check', _check)
      this.$set(this.details, '_half', _half)
      this.$emit('change', this.details)
    },
    handleToggleChilds() {
      this.$set(this.details, '_hiddenChilds', !this.details._hiddenChilds)
    }
  }
}
</script>

<style lang="less" scoped>
.TreeNode {
  padding: 0px 16px;
  background-color: #fff;
  cursor: pointer;

  .self {
    height: 50px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #7D7D7D;
    &.active, &:active, &:hover {
      background: #fff;
      color: #323232;
    }
    .name {
      margin-left: 8px;
      white-space: nowrap;
      padding-right: 0;
    }
  }

  .arrow {
    color: #a5abb4;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>