/*
 * @Author: 张超越 
 * @Date: 2023-08-02 14:27:15 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-02-21 16:44:12
 */

<template>
  <div :class="['Checkbox', (check || half) ? 'active' : '', disabled ? 'disabled' : '' ]" @click.stop="handleClick">
    <van-icon v-show="check || half" :name="getName" size="10px" />
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    check: {
      type: Boolean,
      default: false
    },
    half: {
      type: Boolean,
      default: false
    },
    nameClickCheck: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getName() {
      return this.check ? 'success' : 'minus'
    }
  },
  methods: {
    handleClick() {
      console.log('handleClick')
      if (this.disabled) return
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
.Checkbox {
  width: 17px;
  height: 17px;
  min-height: 17px;
  min-width: 17px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #d2d7df;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &.active {
    background: #EED484;
    border-color: #EED484;
    color: #FFF;
  }

  &.disabled {
    background: #c7c6c6;
    border-color: #c7c6c6;
    color: #FFF;
  }
}
</style>